import React, { useCallback, useRef, useState } from 'react';
import LOGO from './logo.svg';
import './App.css';
import { ZoomMtg } from '@zoomus/websdk';
import { generateSignature } from './Signature';
import axios from 'axios';
import { useMount, useUnmount } from 'ahooks';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av');

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

function App() {
  // var authEndpoint = ''
  
  const sdkKey = process.env.ZOOM_MEETING_SDK_KEY || 'eACatjhuSQ60ZwBGFOkZLg';
  var userEmail = ''
  var registrantToken = ''
  var zakToken = ''
  var leaveUrl = 'https://dolmetsche.ch/meeting-end';
  // var leaveUrl = 'https://dolmetscher.vitec-visual-dev.com/meeting-end';

  // const [encryptedLink, setEncryptedLink] = useState('');

  function validateMeetingArgs(args) {
    if(!(
      args.meeting_number &&
      args.meeting_pwd &&
      args.meeting_email &&
      (
        args.meeting_role != null && 
        [0,1, '0', '1'].includes(args.meeting_role)
      ) &&
      args.meeting_lang &&
      args.meeting_topic &&
      args.display_name &&
      (
        args.meeting_china != null && 
        [0,1, '0', '1'].includes(args.meeting_china)
      ) &&
      args.request_id
    )) {
      return false;
    }

    return true;
  }

  const url = window.location.href;
  const parts = url.split('//');
  const meetingArgs = useRef({});
  const baseApiUrl = 'https://api.dolmetsche.ch/api';
  // const baseApiUrl = 'https://dolmetscher.vitec-visual-dev.com:4000/api';
  // const baseApiUrl = 'http://localhost:4000/api';

  const params = useRef(null);
  const encryptedLink = useRef('');

  const populateMeetingArgs = useCallback(async () => {
    let newParams = [];
    // console.log(parts);
    if(parts.length === 2) {
      const fullEndpoint = parts[1].split('/?');
      if(fullEndpoint.length === 2) {
        params.current = fullEndpoint[1].split('&');
        // console.log("params", params.current);
        // console.log(fullEndpoint);
  
        if(params.current.length) {
          encryptedLink.current = params.current[0].split('=')[1];
          // console.log('encrypted Link', encryptedLink.current, params.current[0].split('=')[1]);

          const decryptionBase = baseApiUrl;
          try {
            const decryption = await axios.post(
              decryptionBase + '/decrypt',
              {
                "value": params.current[0].split('=')[1]
              }
            );
  
            // console.log(decryption.data.data);
            newParams = decryption.data.data.split('&');
          } catch (error) {
            console.log(error);
            // process.exit(1);
          }
  
  
          if(newParams.length) {
            newParams.forEach((keyValue) => {
              const parts = keyValue.split('=');
              meetingArgs.current[parts[0]] = parts[1];
            });
          }
  
          // console.log(meetingArgs.current);
        }
      }
    }
  }, [encryptedLink, parts]);

  async function getSignature() {
    try {
      await populateMeetingArgs();
      // console.log(meetingArgs.current);

      if(validateMeetingArgs(meetingArgs.current)) {
        const validationEndPoint = "/user/request/meeting-link/validate?link="+encryptedLink.current;
        const validateLink = await axios.get(baseApiUrl + validationEndPoint);
        // console.log(validateLink);

        if(!validateLink.data.status) {
          alert(validateLink.data.message);
          return;
        }

        const signature = await generateSignature(
          meetingArgs.current.meeting_number, 
          meetingArgs.current.meeting_role
        );
        startMeeting(signature);
      } else {
        alert('Invalid meeting link');
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);

      alert(error?.response?.data?.message)
    }
  }

  function startMeeting(signature) {
    document.getElementById('nav-tool').style.display = 'none';
    document.getElementById('zmmtg-root').style.display = 'block';

    ZoomMtg.init({
      leaveUrl: leaveUrl + `${encryptedLink.current.length ? '?link=' + encryptedLink.current : ''}`,
      success: (success) => {
        console.log(success)

        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingArgs.current.meeting_number,
          passWord: meetingArgs.current.meeting_pwd,
          userName: meetingArgs.current.display_name,
          userEmail: userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            console.log(success);
          },
          error: (error) => {
            console.log(error)
          }
        });
      },
      error: (error) => {
        console.log(error)
      },
      disableInvite: true,
      showMeetingHeader: true
    });

  };

  useMount(() => {
    async function init() {
      await populateMeetingArgs();
      await getSignature();
      document.getElementById('meeting-topic').innerHTML = meetingArgs.current.meeting_topic;
    }

    init();

    return () => {
      // setMeetingTopic('');
      // setDisplayName('');
    }
  });

  useUnmount(() => {
    async function deInit() {
      ZoomMtg.leaveMeeting({
        confirm: true,
        success: () => {
          console.log('meeting ended');
        }
      });
    }

    deInit();
  });

  const checkCss = setInterval(() => {
    const button = document.querySelector('.joinWindowBtn');
    if(document.body.contains(button)) {
      document.querySelector('.joinWindowBtn').innerHTML = 'Beitreten';
      document.querySelector('.joinWindowBtn').title = 'Beitreten';
      clearInterval(checkCss);
      console.log('one');
    }
  }, 2000);

  return (
    <div className="App">
      <nav id="nav-tool" className="navbar navbar-inverse navbar-fixed-top bg-pri">
        <div className="container bg-pri">
            <div className="navbar-header" id="nav-header">
                <div className="logo">
                    <img src={LOGO} alt="" id="dol-logo"/>
                </div>
                <div className="margin-center">
                    <a className="navbar-brand" id="header-text" href={leaveUrl}>Dolmetscher</a>
                </div>
            </div>
        </div>
        <div className="container meeting-details">
            <div className='container'>
                <p className="color-pri f-weight-900 f-size-3" id="meeting-topic">{"Dolmetscher Meeting"}</p>
            </div>
        </div>
      </nav>
      <main style={{display: 'none'}}>
        <h1>Zoom Meeting SDK Sample React</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
    </div>    
  );
}

export default App;
